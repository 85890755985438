/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 @import '../../../../../../src/style/abstract/variables';
 @import '../../../../../../src/style/abstract/media';
 @import '../../../../../../src/style/abstract/button';
 @import '../../../../../../src/style/abstract/loader';
 @import '../../../../../../src/style/abstract/icons';
 @import '../../../../../../src/style/abstract/parts';
 @import '../../../../../../src/style/abstract/theme-abstract';
 
.CategoryNavigator {
    &-Wrapper {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;

        @include mobile {
            margin-left: -2.4rem;
            margin-right: -2.4rem;
            max-width: calc(100% + 4.8rem);
            padding-left: 1.2rem;
            padding-right: 1.2rem;
            margin-bottom: 2.4rem;
            border-bottom: 1px solid rgba(0,0,0,0.05);
            padding-bottom: 1.2rem;
            padding-top: 1.2rem;
            //background: var(--picment-color-4-1);
        }
        
        &_cmsblock {
            margin: 10.1rem 0 0 0;
            border: 0;

            @include desktop {
                margin: 11.1rem 0 0 0;
                display: none;
            }
        }
    }

    &-Placeholder {
        height: 100%;
        font-size: 2.4rem;
        &::after {
            content: 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
        }
    }

    &-Title {
        font-size: 1.4rem;
        padding: 0.4rem 1.2rem 0.4rem 0;
        display: none;
    }

    &-CategoryList {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-bottom: -.6rem;

        @include mobile {
            flex-wrap: nowrap;
            max-width: 100%;
            overflow: scroll;
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }

    &-Category {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-right: .8rem;
        margin-bottom: .8rem;
        transition: all ease .2s;

        @include desktop {
            background: var(--picment-color-4-1);
            font-size: 1.4rem;
            border: 2px solid transparent;

            &:hover,
            &_isSelected {
                border-color: #727272;
            }
        }

        @include mobile {
            border-bottom: 1px solid transparent;
            
            &_isSelected {
                font-weight: 600;
                border-color: #727272;
            }
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &-CategoryLink {
        text-decoration: none;
        transition: all ease .2s;
        font-size: calc(var(--p-font-size) - 1px);

        @include desktop {
            padding: 0.5rem 2rem;
        }

        @include mobile {
            white-space: nowrap;
            padding: 0 1rem;
        }

        &:hover {
            text-decoration: none;
        }
    }
}