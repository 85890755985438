/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 @import '../../style/abstract/variables';
 @import '../../style/abstract/media';
 @import '../../style/abstract/button';
 @import '../../style/abstract/loader';
 @import '../../style/abstract/icons';
 @import '../../style/abstract/parts';
 @import '../../style/abstract/theme-abstract';
 
.Footer {
    background: var(--picment-color-4-1);

    .Accordion {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include desktop {
            flex-direction: row;
        }
    }
    .Accordion-section {
        flex: 1;
        padding: 1rem 0;
        border-bottom: 1px solid #f7f5ef;

        input[type="checkbox"] {
            display: none;
        }        

        @include desktop {
            padding: 0;
            border-bottom: none;
        }
    }
    .Accordion-title {
        font-weight: 600;
        font-size: var(--p-font-size);
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-transform: uppercase;

        @include desktop { display: block; }

        &_stores { @include desktop {display: none;} }
    }
    .Accordion-title::after {
        content: '+';
        font-size: 1.2em;

        @include desktop { content: ''; }
    }
    .Accordion-links li {
        margin: 1.5rem 0;
        padding: 0;
        line-height: 1.5;
        font-size: 1.5rem;
        @include desktop { font-size: var(--p-font-size); }
    }
    li:before { content: ''; }
    .Accordion-links a {
        text-decoration: none;
        color: var(--picment-color-4-1);
    }
    .Accordion-links { 
        display: none; 
        @include desktop {
            display: block;
        }
    }
    input[type="checkbox"]:checked ~ label.Accordion-title::after { content: '-'; }
    input[type="checkbox"]:checked ~ .Accordion-links { display: block; }
    
    @media (max-width: 768px) {
        //.Accordion-links { display: none; }
        //input[type="checkbox"]:checked ~ label.Accordion-title::after { content: '-'; }
        //input[type="checkbox"]:checked ~ .Accordion-links {  display: block; }
    }
    
    &-Type {
        &_description {
            max-width: var(--max-category-content-width);
            margin: auto;
            text-align: center;
            padding: 2rem;

            @include desktop { padding: 4rem 2rem; }
    
            &_title {
                font-size: 2rem;
                font-family: 'blacker sans';
                color: #010814;
    
                @include desktop { font-size: 3rem; }
            }
        }
        &_main {
            background: #010814;
            text-align: center;
            color: #f7f5ef;
            padding: 2rem;

            a {color: var(--picment-color-4-1);}

            @include desktop { padding: 8rem 2rem; }

            &_coloums {
                max-width: var(--cms-width);
                display: grid;
                grid-template-columns: repeat(1, 1fr);
                grid-template-areas: "info" "links" "news";
                margin: auto;
                gap: 2rem;

                @include desktop {
                    grid-template-columns: repeat(3, 1fr);
                    grid-template-areas: "info news links";
                }

                .sec-info { grid-area: info; }
                .sec-news { grid-area: news; }
                .sec-links { grid-area: links; }

                .title {
                    font-weight: 600;
                    margin-bottom: 1.6rem;
                }
                .title-XL { font-size: 3rem; }

                &_links { 
                    text-align: left; 
                    margin-top: -2rem;
                    @include desktop {
                        text-align: right;
                        margin-top: 0;
                    }
                }
                &_newletter {
                    padding: 0;
                    text-align: left;

                    @include desktop {
                        padding: 0 4rem;
                        background: none;
                        text-align: center;
                    }
                }
                &_contactandstores {
                    text-align: left;

                    &_contact {
                        margin-bottom: 2rem;
                        p {
                            font-size: 1.8rem;
                            line-height: 1.5;
                        }
                        @include desktop { 
                            border-bottom: 1px solid var(--soft-black); 
                            margin-bottom: 0;}
                    }
                    &_stores {
                        .p-xs { font-size: 1rem; font-style: italic;}
                        @include desktop {
                            display: grid !important;
                            grid-template-columns: repeat(3, 1fr);    
                            gap: 1rem;                        
                        }
                    }
                }
            }
            &_links {
                &_social-icons {
                    display: grid;
                    grid-template-columns: repeat(5, 1fr);
                    gap: 25px;
                    position: relative;
                    margin-top: 4rem;

                    @include desktop {
                        position: absolute;
                        right: 0;
                        grid-template-columns: 25px 25px 25px 25px 25px;
                    }
                }
            }
        }
        &_bottom {
            padding: 0 2rem;
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            max-width: var(--cms-width);
            margin: 2rem auto 0;

            @include desktop { 
                grid-template-columns: repeat(3, 1fr); 
                padding: 0;
            }
            .right {
                right: 0;
                @include desktop { position: absolute; }
            }
            .center {
                position: absolute;
                right: 2rem;

                @include desktop { 
                    position: relative;
                    margin: 0 auto;
                    right: auto;
                }
            }
           p { 
                text-align: center;
                margin-top: 1rem;
                font-size: 1rem;
                @include desktop {
                    margin-top: 0;
                    text-align: left;
                }
            }
            .Image-Image {
                height: auto;
                position: relative;
            }
            .Image {  height: auto; }

            &_images {
                margin-bottom: -1rem;
                .emaerket { max-width: 100px; }
                .payments { max-width: 350px; }
                .picment {
                    height:auto;
                    max-width: 120px;
                }
            }
        }
    }
}