/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 @import '../../style/abstract/variables';
 @import '../../style/abstract/media';
 @import '../../style/abstract/button';
 @import '../../style/abstract/loader';
 @import '../../style/abstract/icons';
 @import '../../style/abstract/parts';
 @import '../../style/abstract/theme-abstract';
 
:root {
    --product-card-wishlist-button-background: transparent;
    --product-card-wishlist-button-color: #111;
}

.ProductCard-WishListButton .ProductWishlistButton-Heart {
    --wishlist-heart-size: 25px;
}

.ProductCard {
    margin-bottom: 1rem;
    flex-direction: column;

    &-GlansLabel {
        position: absolute;
        top: 10px;
        z-index: 10;
        background: var(--picment-color-3-1);
        color: #fff;
        padding: 0 8px;
        font-weight: 100;
        pointer-events: none;
        font-size: 9px;
        font-weight: 400;
        letter-spacing: 0.1em;

        @include desktop {
            padding: 0 20px;
            font-size: calc(var(--p-font-size) - 2px);
        }
    }

    &-FixedLowPriceLabel {
        position: absolute;
        top: 10px;
        z-index: 10;
        background: var(--fixedlowprice);
        //background: #65ac83;
        color: #fff;
        padding: 0 8px;
        pointer-events: none;
        font-size: 9px;
        font-weight: 400;
        letter-spacing: 0.1em;

        @include desktop {
            padding: 0 20px;
            font-size: calc(var(--p-font-size) - 2px);
        }
    }

    .PercentageOff + .ProductCard-FixedLowPriceLabel {
        top: 32px;

        @include desktop { 
            top: 41px;
        }
    }
    .PercentageOff + .ProductCard-GlansLabel {
        top: 32px;

        @include desktop { 
            top: 41px;
        }
    }
    .ProductCard-FixedLowPriceLabel + .ProductCard-GlansLabel {
        top: 28px;

        @include desktop { 
            top: 34px;
        }
    }
    .PercentageOff + .ProductCard-FixedLowPriceLabel + .ProductCard-GlansLabel {
        top: 50px;

        @include desktop { 
            top: 66px;
        }
    }

    &-AttributeContainer {
        display: flex;
        justify-content: center;
        position: absolute;
        z-index: 9;
        bottom: 10%;
        font-size: 10px;
        width: calc(100% - 20px);
        gap: .5em;
        overflow: auto;
        margin: 0 10px;
        white-space: nowrap;

        @include desktop {
            font-size: var(--p-font-size);
        }

        &.hasMany {
            justify-content: flex-start;

            &::after {
                content: "";
                background: linear-gradient(to right, transparent, var(--secondary-base-color));
                width: 10px;
                position: absolute;
                right: 0;
                height: 100%;
            }
        }
    }

    &-AttributeLabel {
        flex: 0 1 4.4em;
        padding: 0.2em 0.8em;
        border: 1px solid #eaeaea;
        text-align: center;
        background-color: #fff;

        @include desktop {
            padding: 0.4em 0.8em;;
        }
    }

    &-CustomPhotowallLabel {
        position: absolute;
        top: 40px;
        left: 0;
        font-size: 1rem;
        background: var(--picment-color-4-1);
        padding: 5px 10px;
        display: block;
        color: #000;
        
        @include desktop {
            top: 60px;
            left: 6px;
            font-size: var(--p-font-size);
        }
    }

    &-FigureReview {
        overflow: hidden;
        background: #f5f5f5;

        .Image {
            width: 100%;
            display: block;

            .Image-Image {
                height: 100% !important;
                width: 100% !important;
                object-fit: cover !important;
                transform: scale(1);
                transition: all ease .3s;

                @at-root .CmsPage & {
                    height: 100% !important;
                }
            }

            // &:hover {
            //     .Image-Image {
            //         transform: scale(1.06);
            //     }
            // }
        }
    }

    &-Content {
        padding: .6rem 0 0 0;
    }

    &-Brand {
        display: none;
    }

    &-Name {
        font-size: calc(var(--p-font-size) - 2px);
        text-transform: uppercase;
        color: var(--body-heading-color);
        margin: 0;
        text-align: left;
        letter-spacing: var(--heading-letter-spacing);
        overflow: inherit;
        white-space: normal;
    }

    &-PriceWrapper {
        min-height: 0 !important;
        justify-content: flex-start;
    }

    &-Price {
        font-size: var(--p-font-size);
        margin-top: 0.6em;
        font-weight: 100;

        del {
            font-size: var(--p-font-size);
            font-weight: 500;
            margin-left: .6rem;
            opacity: .4;
        }
        @include desktop {
            font-size: 1.5rem;

            del {
                font-size: 1.5rem;
            }
        }
    }

    &-pricePerUnit {
        font-size: 0.9em;
        text-align: left;
        color: var(--soft-black);
        margin-top: 0.1em;

        .PricePerUnit-Stuk {
            font-weight: 600;
        }
    }

    &-PriceBadge {
        text-align: left;
        margin-bottom: -.6rem;
    }

    &-Picture {
        padding-bottom: 125%;
    }

    &-ShopUsp {
        list-style-type: none;
        line-height: 1.5rem;
        margin-left: -1rem;

        strong {
            font-weight: 600;
        }

        li {
            padding-left: 0;
            display: flex;
            align-items: center;

            &::before {
                content: "";
                display: inline-block;
                position: static;
                left: auto;
                top: .3em;
                width: 1em;
                height: 1em;
                background: url(icons/checkmark.svg) center no-repeat;
                background-size: contain;
                padding-right: 2rem;
            }

            @include desktop {
                font-size: var(--p-font-size);
            }
        }
    }
}

.ShortUsp-Usp {
    order: 40;
    margin: 2.5rem 0 -2rem;
    border-bottom: solid 1px var(--body-content-color-light);
    border-top: 1px solid var(--soft-black);
    padding: 2rem 0 0;
}

.InfoCard {
    display: grid;
    grid-template-columns: 1fr;
    background-color: var(--grey-background-color);
    width: 100%;
    height: 0;

    &_span {
        &_one {
            grid-column: span 1;
            padding-bottom: 125%;

            @include phone {
                grid-column: span 2;
                padding-bottom: 0;
                height: auto;
            }
        }

        &_two {
            grid-column: span 2;
            padding-bottom: calc(63% - 1rem);

            @include phone {
                padding: 0;
                height: auto;
            }
        }
    }

    &-Column {
        position: absolute;
        height: 100%;
        width: 100%;

        @include phone {
            width: 100%;
            position: relative;
            height: auto;
        }

        .InfoCard_useColumns.InfoCard_span_two & {
            width: 50%;

            @include phone {
                width: 100%;
            }
        }

        &_colType {
            &_content {
                .InfoCard_useColumns.InfoCard_span_two & {
                    width: calc(50% + .5rem);

                    @include phone {
                        width: 100%;
                        height: 50%;
                        top: unset;
                        bottom: 0;
                    }
                }
            }

            &_image {
                .InfoCard:not(.InfoCard_useColumns) & {

                    @include phone {
                        height: 100%;
                        position: absolute;
                        padding-bottom: 0;
                    }
                }

                .InfoCard_useColumns.InfoCard_span_two & {
                    left: auto;
                    right: 0;
                    width: calc(50% - .5rem);

                    @include phone {
                        width: 100%;
                        height: 0;
                        padding-bottom: 70%;
                    }
                }
            }
        }
    }

    &-Content {
        position: absolute;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 4.8rem;

        @include phone {
            width: 100%;
            position: relative;
            height: auto;
        }

        .InfoCard_useColumns.InfoCard_span_two & {
            align-items: flex-start;
            text-align: left;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p {
            margin-top: 0 !important;
            margin-bottom: 1.2rem;
        }

        h1,
        h2 {
            margin-bottom: 2.4rem;
        }

        h5,
        h6 {
            text-transform: uppercase;
            color: var(--body-content-color);
            font-weight: 400;
        }

        .cms-button {
            margin-top: 1.2rem;
        }

        .infobox-link {
            color: var(--body-heading-color);
            letter-spacing: var(--heading-letter-spacing);
            font-size: 1.3rem;
            font-weight: 700;
            text-transform: uppercase;
            margin-top: 1.2rem;
            display: flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            transition: all ease .3s;

            &:hover {
                color: var(--body-content-color);
            }

            &::after {
                content: '';
                position: relative;
                background-image: url(icons/arrow-right.svg);
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center left;
                display: inline-block;
                height: 30px;
                width: 30px;
                margin-left: 8px;
            }
        }

        * {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &-Image {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-size: cover;
        background-position: center;
    }
}

.CmsPage .CmsPage-Content .ProductCard .Image .Image-Image {
    width: 100% !important;
    height: 100% !important;
}

.PercentageOff {
    position: absolute;
    top: 10px;
    z-index: 10;
    background: var(--picment-color-4-2);
    padding: 1.5px 20px;
    font-weight: 600;
    pointer-events: none;
    color: white;
    letter-spacing: 0.1em;

    @include mobile {
        padding: 1px 8px;
        font-weight: 500;
        font-size: 1rem;
    }
}

.CmsPage .CmsPage-Content .Image.ProductCard-Picture,
.PostsDetails .PostsDetails-Content .Image.ProductCard-Picture {
    padding-bottom: 125%;

    img {
        position: absolute;
    }
}